<template>
    <v-card class="access-card d-flex align-center" @click="navigate(target)">
        <v-row>
            <v-col class=" d-flex align-center justify-center pa-1" cols="12">
                <v-icon>{{ target.icon }}</v-icon>
            </v-col>
            <v-col class="d-flex align-center justify-center pa-1" cols="12">
                <span>{{ target.text }}</span>
            </v-col>
        </v-row>
    </v-card>
</template>
<style scoped>
.access-card {
    height: 185px;
    /* Adjust this height as per your requirement */
}
</style>
<script>
export default {
    name: 'OutputTargetCard',
    props: {
        target: {
            type: Object,
            required: true,
        },
    },
    data: () => ({}),
    computed: {},
    methods: {
        navigate(item) {
            if (item.route === '/userdashboard') {
                this.$emit('announcements')
            } else {
                console.log('Route: ' + item.route)
                this.$router.push({
                    path: item.route,
                })
            }
        },
    },
}
</script>
