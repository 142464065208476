<template>
    <div>
        <!-- User dashboard section -->
        <v-card style="line-height: 1.5;">
            <v-card-title>
                <h3>{{ dashboardName }}</h3>
            </v-card-title>
            <!-- Iterate over unread notifications and display notification messages -->
            <notification-message
                v-for="item in $store.getters.userForPortalAccess
                    .unreadNotifications"
                :key="item.id"
                :message="item"
            />
        </v-card>

        <template v-if="isClientAdmin || isClientUser">
            <access-grid @announcements="goForward"></access-grid>
        </template>
    </div>
</template>

<script>
import NotificationMessage from '@/components/Layout/NotificationMessage'
import { mapGetters } from 'vuex'
import { authGuard } from '@/auth/authGuard'
import AccessGrid from '@/views/user-dashboard/access/AccessGrid'
export default {
    name: 'UserDashboard',
    components: {
        'notification-message': NotificationMessage,
        'access-grid': AccessGrid,
    },
    computed: {
        ...mapGetters({}), // Map Vuex getters to component's computed properties
        isClientAdmin() {
            return (
                this.$store.getters.userForPortalAccess.user_type ===
                'client_admin'
            )
        },
        isClientUser() {
            return (
                this.$store.getters.userForPortalAccess.user_type ===
                'client_user'
            )
        },
        dashboardName() {
            const userType = this.$store.getters.userForPortalAccess.user_type
            if (userType === 'admin') {
                return 'WD Administrator Dashboard'
            } else if (userType === 'client_admin') {
                return `${this.$store.getters.userForPortalAccess.client.name} Admin Dashboard`
            } else {
                return 'Dashboard'
            }
        },
    },
    mounted() {
        // this.showNewsCard();
    },
    data: () => ({
        itemsPerPageOptions: [4, 8, 12], // Options for number of items per page in pagination
        itemsPerPage: 8, // Default number of items per page
        deleteDialog: false, // Flag to show/hide delete confirmation dialog
        selectedTarget: {}, // Object that holds the currently selected item for deletion
        rando: 0, // Random number for testing purposes
        keywords: '', // Search keywords for filtering results
        client_id: null,
    }),
    methods: {
        beforeRouteEnter(to, from, next) {
            authGuard(to, from, next) // Apply the authGuard to the route
        },
    },
}
</script>
